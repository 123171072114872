// Generated by Framer (2ca17d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["pTS8ng8Yy", "H5XWkT1X_", "CzYPOeRuS", "mqFlSBsfu"];

const serializationHash = "framer-zYxCU"

const variantClassNames = {CzYPOeRuS: "framer-v-ipzy1z", H5XWkT1X_: "framer-v-1bejeme", mqFlSBsfu: "framer-v-1v9m02i", pTS8ng8Yy: "framer-v-1409e73"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 2, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Bottom Left": "mqFlSBsfu", "Bottom RIght": "CzYPOeRuS", "Top Left": "pTS8ng8Yy", "Top Right": "H5XWkT1X_"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "pTS8ng8Yy"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "pTS8ng8Yy", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear17ld8gd = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 1980)
})

useOnVariantChange(baseVariant, {default: onAppear17ld8gd})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1409e73", className, classNames)} data-framer-name={"Top Left"} data-highlight layoutDependency={layoutDependency} layoutId={"pTS8ng8Yy"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24, ...style}} {...addPropertyOverrides({CzYPOeRuS: {"data-framer-name": "Bottom RIght"}, H5XWkT1X_: {"data-framer-name": "Top Right"}, mqFlSBsfu: {"data-framer-name": "Bottom Left"}}, baseVariant, gestureVariant)}><motion.div className={"framer-y85my9"} data-framer-name={"Stroke"} layoutDependency={layoutDependency} layoutId={"j1gvtc9_P"} style={{background: "radial-gradient(10% 15% at 4.6% 0%, var(--token-76f90543-dc6e-4dc7-ac64-24f299a32d66, rgb(0, 166, 255)) /* {\"name\":\"Light Blue\"} */ 0%, rgba(255, 255, 255, 0) 100%)", borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24}} variants={{CzYPOeRuS: {background: "radial-gradient(15% 15% at 96.89999999999999% 100%, var(--token-76f90543-dc6e-4dc7-ac64-24f299a32d66, rgb(0, 166, 255)) /* {\"name\":\"Light Blue\"} */ 0%, rgba(255, 255, 255, 0) 100%)"}, H5XWkT1X_: {background: "radial-gradient(17% 17% at 100% 3.9%, var(--token-76f90543-dc6e-4dc7-ac64-24f299a32d66, rgb(0, 166, 255)) /* {\"name\":\"Light Blue\"} */ 0%, rgba(255, 255, 255, 0) 100%)"}, mqFlSBsfu: {background: "radial-gradient(17% 11% at 0% 97.7%, var(--token-76f90543-dc6e-4dc7-ac64-24f299a32d66, rgb(0, 166, 255)) /* {\"name\":\"Light Blue\"} */ 0%, rgba(255, 255, 255, 0) 100%)"}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zYxCU.framer-bxp3qj, .framer-zYxCU .framer-bxp3qj { display: block; }", ".framer-zYxCU.framer-1409e73 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 500px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 533px; }", ".framer-zYxCU .framer-y85my9 { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-zYxCU.framer-1409e73 { gap: 0px; } .framer-zYxCU.framer-1409e73 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-zYxCU.framer-1409e73 > :first-child { margin-top: 0px; } .framer-zYxCU.framer-1409e73 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 500
 * @framerIntrinsicWidth 533
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"H5XWkT1X_":{"layout":["fixed","fixed"]},"CzYPOeRuS":{"layout":["fixed","fixed"]},"mqFlSBsfu":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerpIFgcQivX: React.ComponentType<Props> = withCSS(Component, css, "framer-zYxCU") as typeof Component;
export default FramerpIFgcQivX;

FramerpIFgcQivX.displayName = "Glow Stroke/Full";

FramerpIFgcQivX.defaultProps = {height: 500, width: 533};

addPropertyControls(FramerpIFgcQivX, {variant: {options: ["pTS8ng8Yy", "H5XWkT1X_", "CzYPOeRuS", "mqFlSBsfu"], optionTitles: ["Top Left", "Top Right", "Bottom RIght", "Bottom Left"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerpIFgcQivX, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})